import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { mq } from '../core/globalstyles'
import Menu from '../core/menu/menu'
import { useColor } from '../core/settings'

const Navigation = ({children}) => {
    const [menuState, setMenuState] = useState("closed")
    const {colorObject} = useColor()
    const menuHandler = () => {
        if (menuState ==="closed") {
            setMenuState("open")
            
        }
        if (menuState ==="open") {
            setMenuState("closed")
            
        }
    }

    return (
        <div>
            <div css={mq({position: "fixed", top: ["20px","20px","20px", "40px"], right: ["20px","20px","20px","100px"], zIndex: 13})}>
            <div style={menuState === "open" ? { height:80} : {height: 40} } onClick={() => menuHandler()}   css={mq({transform: ["scale(0.8)", "scale(0.9)", "scale(1)"],backgroundColor: "transparent", cursor: "pointer",zIndex: 21, position: "relative" })}>
                <motion.div transition={{duration: 0.5, ease: "easeOut"}} animate={menuState==="open" ? {color: colorObject.white} : {color  : colorObject.color1}} css={{height: "auto", width: "100%", fontSize: "16px", textAlign: "center", fontFamily: "Oswald", position: "relative"}}>
                    MENU
                </motion.div>
                <motion.div  css={{height: "auto", width: "100%", position: "relative", height: "8px", zIndex: 20}}>
                    <motion.div animate={menuState === "open" ? {rotate: "-135Deg", y: 26, backgroundColor: colorObject.white } : {rotate: 0, y: 0, backgroundColor: colorObject.color1}} transition={{duration: 0.5, ease: "easeOut"}} css={{position: "absolute", height: "2px", bottom: "6px", width: "100%"}} />
                    <motion.div animate={menuState === "open" ? {rotate: "135Deg", y: 20, backgroundColor: colorObject.white } : {rotate: 0, y: 0, backgroundColor: colorObject.color1}} transition={{duration: 0.5, ease: "easeOut"}} css={{position: "absolute", height: "2px", bottom: "0px", width: "100%"}} />
                </motion.div>
            </div>
            <Menu menuState={menuState} menuHandler={menuHandler} />
            </div>
            {children}
        </div>
    )
}

export default Navigation
