import { useContext } from "react"
import SchemaContext from "../../provider/schemaContext"
//Color Settings

export const useColor = () => {
    const {darkModeBottom, darkModeTop} = useContext(SchemaContext)
    
    let color1 = darkModeTop ? "#181B20" : "#fff"
    let color2 = darkModeBottom ? "#181B20" : "#fff"
    let color3 = "#2B3036"
    let color4 = "#085f63"
    let color5 = "#226089"

    let colorObject = {
        color1: color1,
        color2: color2,
        color3: color3,
        color4: color4,
        color5: color5,
        white: "#fff",
        dark: "#181B20",
        darkgrey: "#2B3036",
        background: "#F0F0F0",
        grey: "#D7D7D7",
        lightgrey: "#F2F2F2",
        red: "#F84525"
    }

    return {
        colorObject
    }


}


// export const color2 = "#facf5a"
// export const color3 = "#49beb7"
// export const color4 = "#085f63"
// export const color5 = "#226089"

export const dark = "#000"
export const darkgrey = "#0f0f0f"
export const grey = "#f9f9f9"
export const lightgrey = "#fefefe"
export const white = "#fff"



export const farbeScrollbar = dark
export const widthScrollbar = "8px"

// Container Settings

export const contentWidth ="1200px"