import styled from "@emotion/styled"
import facepaint from "facepaint"
import { contentWidth } from "./settings"

const breakpoints = [576, 768, 992, 1200]

export const mq = facepaint(
    breakpoints.map(bp => `@media (min-width: ${bp}px)`)
  )

export const MainWrapper = styled.div({
    width: "100vw",
    backgroundColor: "#F0F0F0"
})
export const Wrapper = styled.div({
    margin: "0 auto",
    width: "100%"
})
export const Container = styled.div(mq({
    maxWidth: contentWidth,
    margin: "0 auto",
    height: "auto",
    padding: ["0 1.2rem"]
}))
export const HeadContent = styled.div(mq({
    maxWidth: contentWidth,
    margin: "0 auto",
    padding: "0 3rem",
    height: "auto",
    display: "flex",
    minHeight: "50vh",
    flexDirection: ["column","column","row","row", "row"],
    alignItems: ["flex-start","flex-start","center","center"],
    justifyContent: ["center", "center", "space-evenly"],
    ["h1"]: {fontSize: ["2.6rem", "3.2rem", "4.2rem", "6.6rem"]}
}))
export const MainContent = styled.div(mq({
    width: "100vw",
    margin: "0 auto",
    height: "auto",
    ["h2"]: {fontSize: ["3rem", "3rem", "3.2rem", "3.6rem"]}
}))

export const FlexContainer = styled.div(mq({
    width: "100%",
    display: "flex",
    flexDirection: ["column","column","row","row", "row"],
    alignItems: "center",
    justifyContent: "space-evenly",
    padding: ["6rem 0","6rem 0","6rem 0","9rem 0"]
}))
export const FlexContainerColumn = styled.div({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    padding: ["6rem 0","6rem 0","6rem 0","9rem 0"],
    ["h2"]: {fontSize: ["3rem", "3rem", "3.2rem", "3.6rem"]}
})