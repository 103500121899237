import React, { useState } from 'react'
import SchemaContext from './schemaContext'

const SchemaContextProvider = ({children}) => {
    const [darkModeTop, setDarkModeTop] = useState(false)
    const [darkModeBottom, setDarkModeBottom] = useState(false)
    const colorSchemaHandlerTop = (value) => {
       setDarkModeTop(value)
    }
    const colorSchemaHandlerBottom = (value) => {
       setDarkModeBottom(value)
    }
    return (
        <SchemaContext.Provider
            value={{
                colorSchemaHandlerTop: colorSchemaHandlerTop,
                colorSchemaHandlerBottom: colorSchemaHandlerBottom,
                darkModeTop: darkModeTop || '',
                darkModeBottom: darkModeBottom || '',
            }}
            >
            {children}
        </SchemaContext.Provider>
    )
}

export default SchemaContextProvider
